import React, { useContext } from "react"
import { navigate } from "gatsby"
import Button from "../components/Button"
import Background from "../components/Background"
import { UserContext } from "../context/userContext"
import ToggleMute from "../components/ToggleMute"

export default function RegistrationFormStart() {
  const { user, saveUser } = useContext(UserContext)
  console.log(user)

  return (
    <Background container>
      <div className="d-flex flex-column">
        <h1 className="display-4 font-weight-bold mt-4 ml-4">
          Registration Form
        </h1>

        <h3 className="mt-4 p-4">
          <strong>Instructions:</strong>
          <br />
          1. This form has been designed to provide the Company the fundamental
          information required to determine the contractor's eligibility to
          participate and use{" "}
          <strong>Primarius App or Primarius Contract Work Service.</strong>
          <br />
          <br />
          2. All pertinent sections of this application form must be completed.
          <br />
          <br />
          3. The Company reserves the right to reject or terminate the
          contractor's eligibility to use{" "}
          <strong>Primarius App or Primarius Contract Work Service</strong> if
          any of the information in this registration form is found to be
          fictitious.
        </h3>

        <div className="d-flex flex-row-reverse fixed-top p-4">
          <ToggleMute
            isActive={user.isSoundActive}
            onClick={() => {
              saveUser({
                ...user,
                isSoundActive: !user.isSoundActive,
              })
              if (user.isSoundActive) {
                user.audio && user.audio.pause && user.audio.pause()
              } else {
                user.audio && user.audio.play && user.audio.play()
              }
            }}
          />
        </div>

        <div className="d-flex flex-row-reverse fixed-bottom p-4">
          <Button
            text="I Agree to Proceed"
            size="medium"
            onClick={() => navigate("/registration-form-a")}
          />
          <Button
            size="medium"
            text="Back"
            onClick={() => navigate("/job-application-options")}
          />
        </div>
      </div>
    </Background>
  )
}
